import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { Button, LoaderButton } from "../components/button";
import Heading from "../components/heading/Heading";
import LabeledInput from "../components/labeledInput/LabeledInput";
import ConfirmationForm from "../components/confirmationForm/ConfirmationForm";
import { useToast } from "../components/toast";
import "./Login.css";
import { config } from "../config";
import { auditLog } from "../libs/auditLib";

export default function Login() {
  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState({});

  const toast = useToast();
  const [fields, handleFieldChange] = useFormFields({
    username: "",
    email: "",
    password: "",
  });

  function validateForm() {
    return fields.username.length > 0 && fields.password.length > 0;
  }

  function getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  const updateLoginAttempts = (username) => {
    setLoginAttempts((prevLoginAttempts) => {
      if (prevLoginAttempts.hasOwnProperty(username)) {
        return {
          ...prevLoginAttempts,
          [username]: {
            username,
            attempts: prevLoginAttempts[username].attempts + 1,
          },
        };
      } else {
        return {
          ...prevLoginAttempts,
          [username]: {
            username,
            attempts: 1,
          },
        };
      }
    });
  };

  useEffect(() => {
    if (!loginAttempts) return;
    Object.keys(loginAttempts)
      .filter((entry) => entry.attempts >= 5)
      .map((entry) => {
        var logDetails = {
          type: "BruteForceAttack",
          message: `Incorrect login provided for user ${entry} ${loginAttempts[entry].attempts} times (or more). Someone may be trying to brute force this account!`,
        };

        if (loginAttempts[entry].attempts === 5) logDetails.severity = "minor";
        else if (loginAttempts[entry].attempts === 10)
          logDetails.severity = "moderate";
        else if (loginAttempts[entry].attempts === 20)
          logDetails.severity = "major";

        auditLog(logDetails);
        return entry;
      });
  }, [loginAttempts]);

  async function handleSubmit(event) {
    event.preventDefault();
    if (!validateForm()) {
      return false;
    }
    setIsLoading(true);

    var poolData = {
      UserPoolId: config().cognito.userPoolId,
      ClientId: config().cognito.appClientId,
    };
    var userPool = new CognitoUserPool(poolData);

    const authenticationData = {
      Username: fields.username.toLowerCase(),
      Password: fields.password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    var userData = {
      Username: fields.username.toLowerCase(),
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");

    await cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async function (result) {
        console.log("onSuccess", result);
        const userinfo = await Auth.currentUserInfo();

        var enteredAge = getAge(new Date(userinfo.attributes.birthdate));

        if (enteredAge < 18) {
          alert("Sorry, users must be 18 and over to continue");
          await Auth.signOut();
          history.push("/");
          return;
        }

        userHasAuthenticated(true);
      },

      onFailure: function (err) {
        switch (err.code) {
          case "UserNotConfirmedException":
            setIsConfirming(true);
            break;

          case "UserNotFoundException":
            toast.add("Incorrect email or password");
            break;

          case "NotAuthorizedException":
            toast.add("Incorrect email or password");
            break;

          default:
            toast.add("Something went wrong, please try again later");
            break;
        }

        setIsLoading(false);
      },
    });
  }

  function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
      return null;
    }
    if (!results[2]) {
      return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const redirect = querystring("redirect");

  if (isConfirming) {
    return (
      <ConfirmationForm
        username={fields.username}
        password={fields.password}
      ></ConfirmationForm>
    );
  }

  return (
    <div className="LoginPage">
      <div className="Login">
        <Heading
          className="Login__title"
          level="h1"
          colour="red"
          uppercase
          bold
        >
          Login
        </Heading>
        <p>If you have an account, sign in with your username.</p>

        <form onSubmit={handleSubmit}>
          <LabeledInput
            labelText="Email"
            type="text"
            id="username"
            name="username"
            value={fields.username}
            onChange={handleFieldChange}
          ></LabeledInput>
          <LabeledInput
            labelText="Password"
            type="password"
            id="password"
            name="password"
            value={fields.password}
            onChange={handleFieldChange}
          ></LabeledInput>

          <div className="Login__forgot-password-wrapper">
            <a
              className="Login__forgot-password"
              href={config().forgotPasswordUrl}
            >
              Forgot password?
            </a>
          </div>
          <LoaderButton
            className="Login__button"
            type="submit"
            isLoading={isLoading}
          >
            Login
          </LoaderButton>
        </form>
      </div>

      <div className="SignUp">
        <Heading
          className="SignUp__title"
          level="h1"
          colour="red"
          uppercase
          bold
        >
          New Users
        </Heading>

        <p>
          You can gain access to eAcademy as a benefit of your LFC Junior
          Membership or through enrolling on an LFC International Academy
          coaching program.
        </p>
        <p>
          Simply sign-up to eAcademy and then enter the code you have been
          provided with to get started.
        </p>

        {/* 
                <div className="Login__price-wrapper">
                    <div className="Login__price">
                        <p className="SignUp__price-desc">Access for 1 month</p>
                        <span className="SignUp__price">£4.99</span>
                    </div>

                    <div className="Login__price">
                        <p className="SignUp__price-desc">Access for 1 year</p>
                        <span className="SignUp__price">£49.99</span>
                    </div>
                </div> */}

        <Button
          className="SignUp__button"
          to={
            redirect === "" || redirect === null
              ? "/signup"
              : "/signup?redirect=" + redirect
          }
        >
          Register
        </Button>
      </div>
    </div>
  );
}
